import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import moment from 'moment'

interface BonusListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  merchants: any[];
  filter: any;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  documents: any[];
}

const $state: BonusListState = {
  loading: false,
  loaded: false,
  fatalError: false,
  merchants: [],

  filter: {
    merchants: [],
    categories: [],
  },
  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  documents: [],
}

export const $actions: ActionTree<BonusListState, RootState> = {
  loadData({ commit, getters, dispatch }, filter) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      let params: any = {}

      if (filter) {
        params = {
          merchant_id: filter.merchant_id,
          merchants: filter.merchants.length ? JSON.stringify(filter.merchants.map((i: any) => i.id)) : null,
          status: filter.status,
          category: filter.category,
          name: filter.name,
        }
      }

      // if (query.sortField) {
      //     params.order_by = query.sortField;
      //     params.order_direction = query.sortDirection || 'asc';
      // }

      // params.page = query.page || 1;

      // if (query.name) {
      //     params.search = query.name;
      // }

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/bonus', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')
          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })

      const param: any = {}
      param.per_page = 1000
      vm.$http
        .get(`/admins/rest/v1/merchants`, { params: param })
        .then((response: AxiosResponse) => {
          commit('SET_MERCHANTS', response.data)

        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  resetFilterState({ commit, dispatch }, predefined) {
    commit('SET_FILTER_DEFAULTS')
    // dispatch(`loadData`, {silent: true})
  },
}

const compare: any = ((a: any, b: any) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
})

export const $mutations: MutationTree<BonusListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },

  SET_MERCHANTS: (state, { merchants }) => {
    state.merchants = merchants.sort(compare)
  },
  SET_ITEMS_LIST(state, data) {
    state.documents = data.bonus.map((item: any) => {
      item.editable = true // moment(item.start_date).add(12, 'hours') > moment();
      return item
    })

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },
  SET_FILTER_DEFAULTS: (state) => {
    state.filter = {
      merchants: [],
      status: null,
      category: null,
    }
  },
}

export const $getters: GetterTree<BonusListState, RootState> = {}

export const STORE_KEY = '$_bonus_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
