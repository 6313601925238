




























































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import moment from 'moment'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'fatalError',

      'filter', 'merchants',

      'documents', 'lastMeta',
    ]),
  },
  methods: {
    ...mapActions(STORE_KEY, [
      'exportData',
    ]),
  },
  components: {
    TitleBar,
  },
})
export default class BonusList extends Vue {

  public showModal: any = false
  public defaultOpenedDetails: any[] = []
  public filter!: any

  public setFilter() {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.filter)
    this.showModal = false
  }

  public statusActive(start_time: any, end_time: any) {
    if (moment(end_time).add('1', 'day') > moment() && moment(start_time) < moment()) {
      return true
    }
  }

  public statusArchive(end_time: any) {
    if (moment(end_time).add('1', 'day') < moment()) {
      return true
    }
  }

  public statusEarly(start_time: any) {
    if (moment(start_time) > moment()) {
      return true
    }
  }

  public resetFilter() {
    this.$store.dispatch(`${STORE_KEY}/resetFilterState`)
    // this.showModal = false;
  }

  get titleStack() {
    return [
      'Бонусы',
    ]
  }

  public getQueryParam(field: string) {
    return this.$route.query ? cloneDeep(this.$route.query[field]) : null
  }

  // ---------------------------------------------------------------------------


  public onSearch(filter: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.filter)
  }

  public onPageChange(page: string) {
    this.$router.replace({
      query: { ...this.$route.query, page },
    })
  }

  // ---------------------------------------------------------------------------

  @Watch('$route.query')
  public handleParamsChange() {
    this.$store.dispatch(`${STORE_KEY}/loadData`)
  }

  // ---------------------------------------------------------------------------

  public openDocument(row: any) {
    this.$router.push({
      name: 'bonus-edit',
      params: {
        id: row.id,
      },
    })
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
